// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.aem.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// `default` environement maps to `dev` environement
export const environment = {
  applicationId: "allianz-leadform-wsa",
  stage: "default",
  elcaLeadsApi: "/",
  elcaLeadApiSecret: "random-secret",
  mailServer: "https://allianz-mailer.lespals.com/sendmail_prod.php",
  //mailServer: 'https://lesmail.de/sendmail_prod.php',
  apiServerRoot: "",
  deployUrl: "/",
  baseUrl: "/",
  baseHref: "/",
  assetsBasePath: "/assets",
  adobeUrl: "",
  production: false,
  client: "allianz",
};
