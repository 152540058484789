<nx-small-stage></nx-small-stage>
<div nxLayout="grid maxwidth">
  <div nxRow>
    <div class="intro" nxCol="12,12,10">
      <h1>
        <span class="highlight">Welche Versicherung</span> benötigt Ihr
        Unternehmen?
      </h1>
      <button nxButton="primary" type="button" (click)="startAdvisor()">
        Jetzt Unternehmenscheck starten
      </button>
    </div>
  </div>
</div>
