<header nx-header>
  <nx-header-row nxLayout="grid maxwidth">
    <nx-header-brand>
      <nx-link>
        <a href="https://www.allianz.ch/de/privatkunden.html">
          <figure nxFigure>
            <img src="assets/img/allianz-logo.svg" alt="Allianz" role="none" />
          </figure>
        </a>
      </nx-link>
    </nx-header-brand>
    <nx-header-actions>
      <app-language-switcher></app-language-switcher>
    </nx-header-actions>
  </nx-header-row>
</header>
<section>
  <router-outlet></router-outlet>
</section>
<footer>
  <div nxLayout="grid maxwidth">
    <nx-footer copyright="Allianz">
      <nx-footer-navigation>
        <nx-footer-link>
          <a href="{{ 'footer.link_1_url' | translate }}" target="_blank">{{
            "footer.link_1_label" | translate
          }}</a>
        </nx-footer-link>
        <nx-footer-link>
          <a href="{{ 'footer.link_2_url' | translate }}" target="_blank">{{
            "footer.link_2_label" | translate
          }}</a>
        </nx-footer-link>
        <nx-footer-link>
          <a href="{{ 'footer.link_3_url' | translate }}" target="_blank">{{
            "footer.link_3_label" | translate
          }}</a>
        </nx-footer-link>
      </nx-footer-navigation>
    </nx-footer>
  </div>
</footer>
