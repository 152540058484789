<div nxLayout="grid maxwidth" class="nx-margin-top-2m">
  <nx-single-stepper>
    <nx-step label="Grundangaben Firma">
      <app-form-step icon="product-bank-monument-info">
        <h3>Wie heisst Ihre Firma?</h3>
        <nx-formfield
          label="Firmenname"
          appearance="outline"
          floatLabel="always"
        >
          <input nxInput />
        </nx-formfield>
        <h3>Welche Rechtsform hat Ihr Unternehmen?</h3>
        <nx-formfield
          label="Rechtsform"
          appearance="outline"
          floatLabel="always"
        >
          <nx-dropdown>
            <nx-dropdown-item value="Option 1"></nx-dropdown-item>
            <nx-dropdown-item value="Option 2"></nx-dropdown-item>
            <nx-dropdown-item value="Option 3"></nx-dropdown-item>
          </nx-dropdown>
        </nx-formfield>
        <h3>In welchem Jahr wurde Ihr Unternehmen gegründet?</h3>
        <nx-formfield
          label="Gründungsjahr"
          appearance="outline"
          floatLabel="always"
        >
          <input nxDatefield nxInput [datepicker]="datepicker" />
          <nx-datepicker-toggle
            [for]="datepicker"
            nxFormfieldSuffix
          ></nx-datepicker-toggle>
          <nx-datepicker #datepicker></nx-datepicker>
        </nx-formfield>
        <h3>In welcher Branche ist Ihr Unternehmen tätig?</h3>
        <nx-formfield label="Branche" appearance="outline" floatLabel="always">
          <nx-dropdown>
            <nx-dropdown-item value="Option 1"></nx-dropdown-item>
            <nx-dropdown-item value="Option 2"></nx-dropdown-item>
            <nx-dropdown-item value="Option 3"></nx-dropdown-item>
          </nx-dropdown>
        </nx-formfield>
        <div class="action-buttons">
          <button nxButton="secondary" role="button" nxStepperPrevious>
            Zurück
          </button>
          <button nxButton role="button" nxStepperNext>Weiter</button>
        </div>
      </app-form-step>
    </nx-step>
    <nx-step>
      <app-form-step icon="product-clock">
        <h3>
          Wie viele Mitarbeitenden sind in Ihrem Unternehmen, Sie
          eingeschlossen, ?
        </h3>
        <nx-formfield label="Branche" appearance="outline" floatLabel="always">
          <nx-dropdown>
            <nx-dropdown-item value="Option 1"></nx-dropdown-item>
            <nx-dropdown-item value="Option 2"></nx-dropdown-item>
            <nx-dropdown-item value="Option 3"></nx-dropdown-item>
          </nx-dropdown>
        </nx-formfield>
        <h3>
          Wie viele Mitarbeitenden sind in Ihrem Unternehmen, Sie
          eingeschlossen, ?
        </h3>
        <nx-formfield label="Branche" appearance="outline" floatLabel="always">
          <nx-dropdown>
            <nx-dropdown-item value="Option 1"></nx-dropdown-item>
            <nx-dropdown-item value="Option 2"></nx-dropdown-item>
            <nx-dropdown-item value="Option 3"></nx-dropdown-item>
          </nx-dropdown>
        </nx-formfield>
        <div class="action-buttons">
          <button nxButton="secondary" role="button" nxStepperPrevious>
            Zurück
          </button>
          <button nxButton role="button" nxStepperNext>Weiter</button>
        </div>
      </app-form-step>
    </nx-step>
    <nx-step>
      <app-form-step nextStepLabel="Zur Auswertung">
        <div class="action-buttons">
          <button nxButton="secondary" role="button" nxStepperPrevious>
            Zurück
          </button>
          <button nxButton role="button" (click)="submitForm()">
            Zur Auswertung
          </button>
        </div>
      </app-form-step>
    </nx-step>
  </nx-single-stepper>
</div>
