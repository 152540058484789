<section class="mandatory">
  <div nxLayout="grid maxwidth">
    <div nxRow>
      <div nxCol="12,12,8">
        <h2>Vielen Dank</h2>
        <h3>
          Anhand Ihrer Angaben empfehlen wir Ihnen die folgenden Versicherungen:
        </h3>
      </div>
    </div>
  </div>
</section>
<section class="optional"></section>
<section class="leadform"></section>
