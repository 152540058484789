import { Component } from "@angular/core";
import { Router, RouterLink } from "@angular/router";
import { NxButtonComponent } from "@aposin/ng-aquila/button";
import {
  NxColComponent,
  NxLayoutComponent,
  NxRowComponent,
} from "@aposin/ng-aquila/grid";
import { NxSmallStageComponent } from "@aposin/ng-aquila/small-stage";

@Component({
  selector: "app-landing-page-intro",
  standalone: true,
  imports: [
    NxButtonComponent,
    NxSmallStageComponent,
    NxLayoutComponent,
    NxRowComponent,
    NxColComponent,
    RouterLink,
  ],
  templateUrl: "./landing-page-intro.component.html",
  styleUrl: "./landing-page-intro.component.scss",
})
export class LandingPageIntroComponent {
  constructor(private router: Router) {}

  startAdvisor() {
    this.router.navigate(["advisor"]);
  }
}
