import { Component } from "@angular/core";
import { LandingPageIntroComponent } from "../landing-page-intro/landing-page-intro.component";
import {
  NxColComponent,
  NxLayoutComponent,
  NxRowComponent,
} from "@aposin/ng-aquila/grid";
import { ServiceStripComponent } from "../service-strip/service-strip.component";
import { Router } from "@angular/router";

@Component({
  selector: "app-landing-page",
  standalone: true,
  imports: [
    LandingPageIntroComponent,
    NxLayoutComponent,
    NxRowComponent,
    NxColComponent,
    ServiceStripComponent,
  ],
  templateUrl: "./landing-page.component.html",
  styleUrl: "./landing-page.component.scss",
})
export class LandingPageComponent {
  constructor(private router: Router) {}

  startAdvisor() {
    this.router.navigate(["advisor"]);
  }
}
