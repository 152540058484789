<div class="nx-margin-top-l" nxLayout="grid maxwidth">
  <div nxRow>
    <div nxCol="12,12,6">
      <ng-content></ng-content>
    </div>
    <div
      nxCol="5"
      colOffset="1"
      class="icon-container nx-hidden-xs nx-hidden-s"
    >
      @if (icon) {
        <nx-icon [name]="icon"></nx-icon>
      }
    </div>
  </div>
</div>
