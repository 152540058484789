import { Component } from "@angular/core";
import { NxGridModule } from "@aposin/ng-aquila/grid";
import {
  NxProgressStepperModule,
  NxStepperNextDirective,
  NxStepperPreviousDirective,
} from "@aposin/ng-aquila/progress-stepper";
import { FormStepComponent } from "../form-step/form-step.component";
import { NxFormfieldModule } from "@aposin/ng-aquila/formfield";
import { NxInputModule } from "@aposin/ng-aquila/input";
import { NxDropdownModule } from "@aposin/ng-aquila/dropdown";
import {
  NxDatefieldModule,
  NxNativeDateModule,
} from "@aposin/ng-aquila/datefield";
import { NxButtonModule } from "@aposin/ng-aquila/button";
import { Router } from "@angular/router";

@Component({
  selector: "app-advisor-form",
  standalone: true,
  imports: [
    NxProgressStepperModule,
    NxGridModule,
    FormStepComponent,
    NxFormfieldModule,
    NxInputModule,
    NxDropdownModule,
    NxDatefieldModule,
    NxNativeDateModule,
    NxButtonModule,
    NxStepperNextDirective,
    NxStepperPreviousDirective,
  ],
  templateUrl: "./advisor-form.component.html",
  styleUrl: "./advisor-form.component.scss",
})
export class AdvisorFormComponent {
  constructor(private router: Router) {}
  submitForm() {
    this.router.navigate(["results"]);
  }
}
