<app-landing-page-intro></app-landing-page-intro>
<div id="content" nxLayout="grid maxwidth">
  <div nxRow class="row">
    <p nxCol="12,12,6">
      Jedes Unternehmen, egal ob Mini-Imperium oder Ein-Personen-Betrieb, ist
      einzigartig. Das Gleiche gilt auch für den Versicherungsbedarf. Damit Sie
      genau wissen, wie viel und welche Absicherung Sie und Ihr Unternehmen
      benötigen, bietet die Allianz den Versicherungs-Check für Unternehmen an.
      Dieser zeigt Ihnen, welche Versicherung für Ihr Unternehmen sinnvoll oder
      sogar obligatorisch ist. Basierend auf Ihrem individuellen Geschäftsmodell
      und mit nur wenigen Klicks, direkt auf Ihre Mail.
    </p>
    <div nxCol="12,12,6">
      <app-service-strip
        label="Mit wenigen Klicks den Versicherungsbedarf ermitteln"
        icon="product-clock"
      ></app-service-strip>
      <app-service-strip
        label="Empfehlungen direkt aufs Mail"
        icon="product-at-sign"
      ></app-service-strip>
      <app-service-strip
        label="Von unverbindlicher Beratung profitieren"
        icon="product-partner-handshake"
      ></app-service-strip>
    </div>
  </div>
  <div
    nxRow
    class="nx-margin-top-xl nx-hidden-m nx-hidden-l nx-hidden-xl nx-hidden-2xl nx-hidden-3xl"
  >
    <div nxCol="12">
      <button
        class="action"
        nxButton="primary"
        type="button"
        (click)="startAdvisor()"
      >
        Jetzt Unternehmenscheck starten
      </button>
    </div>
  </div>
</div>
