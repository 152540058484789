import { Component } from "@angular/core";
import { NxGridModule } from "@aposin/ng-aquila/grid";

@Component({
  selector: "app-results-page",
  standalone: true,
  imports: [NxGridModule],
  templateUrl: "./results-page.component.html",
  styleUrl: "./results-page.component.scss",
})
export class ResultsPageComponent {}
