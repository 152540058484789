import { Component, Input } from "@angular/core";
import { NxIconComponent } from "@aposin/ng-aquila/icon";

@Component({
  selector: "app-service-strip",
  standalone: true,
  imports: [NxIconComponent],
  templateUrl: "./service-strip.component.html",
  styleUrl: "./service-strip.component.scss",
})
export class ServiceStripComponent {
  /**
   * icon name provided by ndbx icons<br>
   * List of icons https://ngx-ndbx.frameworks.allianz.io/documentation/icon/overview
   */
  @Input() icon?: string;
  @Input() label!: string;
}
