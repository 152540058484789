import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

// -------- App Pages ---------------------
import { LandingPageComponent } from "./modules/landing-page/landing-page.component";
import { AdvisorFormComponent } from "./modules/advisor-form/advisor-form.component";
import { ResultsPageComponent } from "./modules/results-page/results-page.component";
// -------- End App Pages -----------------

export const appRoutes: Routes = [
  {
    path: "",
    component: LandingPageComponent,
    pathMatch: "full",
  },
  {
    path: "home",
    component: LandingPageComponent,
  },
  {
    path: "advisor",
    component: AdvisorFormComponent,
  },
  {
    path: "results",
    component: ResultsPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { useHash: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
