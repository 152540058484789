import { Component, Input } from "@angular/core";
import { NxGridModule } from "@aposin/ng-aquila/grid";
import { NxIconModule } from "@aposin/ng-aquila/icon";

@Component({
  selector: "app-form-step",
  standalone: true,
  imports: [NxIconModule, NxGridModule],
  templateUrl: "./form-step.component.html",
  styleUrl: "./form-step.component.scss",
})
export class FormStepComponent {
  @Input() icon?: string;

  constructor() {}
}
