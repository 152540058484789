import { PlatformLocation } from "@angular/common";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import {
  defaultFormUrlProviderFactory,
  FormUrlProvider,
} from "./core/services/sources/sources.service";

export function formUrlProviderFactory(
  location: PlatformLocation,
  translateService: TranslateService,
  route: ActivatedRoute,
): FormUrlProvider {
  const defaultProvider = defaultFormUrlProviderFactory(
    location,
    translateService,
  );
  return (source, _formName, _sourceId): string => {
    if (route.snapshot.queryParams.sc == "mail") {
      return defaultProvider(source, _formName, _sourceId);
    }
    return location.href;
  };
}
declare const __tag: unique symbol;
export type Tagged<T, Tag> = T & { [__tag]: Tag };

/**
 * Temporarily overrides the current lungage used by translation service for the duration of execution of this function
 * to allow for obtaining of the translations specific to `languagecode` inside provided function `fn`.
 * The return value is the same as a return value of `fn`
 * @param languagecode Lowercased language code
 * @param translateService TranslateService instance
 * @param fn Function for the scope of which the `translateService` will have `languagecode` set.
 * @returns Returns the value returned by function `fn`
 */
export async function withLanguage<T>(
  translateService: TranslateService,
  languagecode: string,
  fn: () => T,
): Promise<T> {
  // We save the application language and change the currentLanguage to the agency language
  // This is to retrieve the agency local subject for the email we are gonna send
  // The agency language translations are hopefully loaded by now here
  // The translations might not be loaded if agency language is different then application language
  // and user is on slower network or user clcks Submit button right after entering their zipCode
  // then we restore the application language.
  // Doing this by using `set currentLang(lang)` doesn't cause application to rerender
  // with updated language so it's perfect for getting instant value of translation
  // Better way would be to ise Observables on template Rendere (not implemented) and on translation service
  // before we send the email.
  const currentLanguage = translateService.currentLang;
  return new Promise((resolve) => {
    translateService.use(languagecode.toLowerCase()).subscribe(() => {
      const retval = fn();
      translateService.use(currentLanguage);
      resolve(retval);
    });
  });
}
