import { Component } from "@angular/core";
import { NxLinkComponent } from "@aposin/ng-aquila/link";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-language-switcher",
  standalone: true,
  imports: [NxLinkComponent],
  templateUrl: "./language-switcher.component.html",
  styleUrl: "./language-switcher.component.css",
})
export class LanguageSwitcherComponent {
  constructor(private translateService: TranslateService) {}

  changeLanguage(lang: string) {
    this.translateService.use(lang);
  }
}
